import React, { Component } from "react";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  // MDBTable,
} from "mdbreact";
import "moment/locale/id.js";
import CurrencyFormat from "react-currency-format";
import { map, isEmpty } from "lodash";
import { Link } from "react-router-dom";
import remSep from "string-remove-thousand-separators";

const moment = require("moment");

class Wawancara_detail extends Component {
  constructor(props) {
    super(props);

    // const prmbayar = JSON.parse(localStorage.getItem("DATA_PARAMETER")).Prm_bayar;
    // const prmpotong = JSON.parse(localStorage.getItem("DATA_PARAMETER")).Prm_potong;

    this.state = {
      student_id: "",
      student_detail: {},
      is_show: false,
      isLoading: false,
      isLoading2: false,
      note_verification: "",
      interview_status_id: "",
      upp: "",
      potongan: 0,
      nominal: "",
      dp: "",
      termin: "1",
      fields: {},
      listPdf: {},
      payment: {},
      angsuran: "",
      us: "",
      uk: "",
      ukg: "",
      pomg: "",
      totalus: "",
      // prmbayar: prmbayar,
      // prmpotong: prmpotong,
      cicilan: [{
        angsuran: 0
      }],
      jumlah_spp: 0,
      dateNow: "",
    };
  }

  handleClick = (param) => (e) => {
    e.preventDefault();

    // localStorage.setItem("student_id", param);
    window.open("/detailstudent", "_blank");
  };
  getFiles(files) {
    this.setState({
      encrypt_64: files[0].base64,
      original_name: files[0].name,
    });
  }

  componentDidMount() {
    const student = JSON.parse(localStorage.getItem("STUDENT_DETAIL"));
    let paymentData;
    var totalDP = 0;
    var totalPotongan = 0;
    const date = new Date();

    if (parseInt(student.interview_status_id) === 1 || parseInt(student.interview_status_id) === 2) {
      const datax = student.interview_result;
      paymentData = datax.map((data, index) => {
        if (parseInt(data.is_routine) === 0) {
          data.errtermin = '';
          data.errcicil = '';
          if ('prm_potong' in data) {
            data.prm_potong.map((data2) => {
              data2.id = data2.potong_id;
              return data2;
            })
          } else {
            data.prm_potong = []
          }
          // if ('discount' in data) {
          //   totalPotongan = data.discount
          // }
          if (data.discount !== null || data.discount !== 0) {
            data.totalDiscount = (parseFloat(data.nominal) * data.discount) / 100
          }
          if (parseInt(data.dp) !== 0 && data.dp !== null) {
            var valueTermin = data.dtcicilan.length - 1;
            data.termin = valueTermin;
            const dataCicilanNew = [];
            data.dtcicilan.filter((data2) => {
              let period = data2.periode
              if (data2.periode.length > 3) {
                period = data2.periode.slice(-3);
              }
              if (period !== '000') {
                let paymentsDate = null;
                if (data2.payments_due_date) {
                  paymentsDate = moment(data2.payments_due_date).format('YYYY-MM-DD');
                }
                data2.tgl_angsuran = paymentsDate
                dataCicilanNew.push(data2);
              }
              return data2
            })
            data.dtcicilan = dataCicilanNew;
          } else {
            var valueTermin1 = data.dtcicilan.length;
            data.termin = valueTermin1;
            data.dtcicilan.filter((data2) => {
              let paymentsDate = null;
              if (data2.payments_due_date) {
                paymentsDate = moment(data2.payments_due_date).format('YYYY-MM-DD');
              }
              data2.tgl_angsuran = paymentsDate
              return data2;
            })
          }
        } else {
          data.prm_potong = [];
        }
        return data;
      })
    } else {
      let data = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prm_payment_interview;
      paymentData = [...data].sort((a, b) => a.is_routine - b.is_routine);
      paymentData.map((data) => {
        data.nominal = data.nominal ? data.nominal : data.value;
        if (parseInt(data.is_routine) === 0) {
          if (parseInt(data.potongan_percent) !== 0 || data.potongan_percent !== null) {
            totalPotongan = (parseFloat(data.nominal) * parseFloat(data.potongan_percent)) / 100;
          } else {
            totalPotongan = 0;
          }

          if (parseInt(data.dp_percent) !== 0 || data.dp_percent !== null) {
            totalDP = ((parseFloat(data.nominal) - totalPotongan) * parseFloat(data.dp_percent)) / 100;
          } else {
            totalDP = 0;
          }
          data.discount = data.potongan_percent;
          data.totalDiscount = totalPotongan;
          data.dp = totalDP;
          data.termin = 0;
          data.errtermin = '';
          data.errcicil = '';
          data.dtcicilan = [];
        }
        data.prm_potong.map((data2) => {
          data2.nominal = data2.nominal ? data2.nominal : data2.value;
          return data2;
        });
        if (data.hasOwnProperty('dtcicilan')) {
          data.dtcicilan.map((data3) => {
            data3.nominal = 0;
            return data3;
          });
        }
        return data;
      });
    }
    this.setState({
      student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
      fields: student,
      interview_status_id: student.interview_status_id,
      listPdf: JSON.parse(localStorage.getItem("DATA_PARAMETER")),
      is_show: true,
      payment: paymentData,
      note_verification: JSON.parse(localStorage.getItem("STUDENT_DETAIL")).note_verification || '',
      dateNow: date.toISOString().split('T')[0],
    });
  }

  createPotonganForm(data, subidx) {
    let items = []
    items.push(
      <div className="form-group col-md-12">
        <label className="font-weight-bold">Potongan (Persen)</label>
        <CurrencyFormat
          className="form-control"
          input="number"
          name="potongan"
          onChange={this.onChangeNomArr}
          value={data.discount || 0}
          maxLength="15"
          thousandSeparator={true}
          style={{ width: "100%" }}
          required
          fieldname="payment"
          index={subidx}
        />
      </div>
    )
    items.push(
      <div className="form-group col-md-12">
        <label className="font-weight-bold">Total Potongan</label>
        <CurrencyFormat
          className="form-control"
          input="number"
          name="potongan"
          value={data.totalDiscount || 0}
          maxLength="15"
          thousandSeparator={true}
          style={{ width: "100%" }}
          required
          fieldname="payment"
          index={subidx}
          disabled
        />
      </div>
    )
    return items
  }

  createDPForm(data, subidx) {
    let items = []

    items.push(
      <div className="form-group col-md-12">
        <label className="font-weight-bold">DP</label>
        <CurrencyFormat
          className="form-control"
          input="number"
          name="dp"
          onChange={this.onChangeNomArr}
          value={data.dp || 0}
          maxLength="15"
          thousandSeparator={true}
          style={{ width: "100%" }}
          fieldname="payment"
          index={subidx}
        />
      </div>
    )
    return items
  }

  createSPPForm() {
    let items = []

    const totalSPP = this.state.payment.reduce((sum, data) => {
      return parseFloat(sum) + (parseInt(data.is_routine) === 1 ? parseFloat(data.nominal || 0) : 0)
    }, 0)

    this.state.jumlah_spp = totalSPP

    items.push(
      <div className="form-group col-md-12">
        <label className="font-weight-bold">SPP</label>
        <CurrencyFormat
          className="form-control"
          input="number"
          name="spp"
          value={this.state.jumlah_spp}
          maxLength="25"
          thousandSeparator={true}
          style={{ width: "100%" }}
          fieldname="spp"
          disabled={true}
        // index={subidx}
        />
      </div>
    )
    return items
  }

  createCicilanForm(data, subidx) {
    let items = []
    let currency = Intl.NumberFormat("en-ID");
    // hitung jumlah cicilan
    data.ttl_cicilan = 0
    map(data.dtcicilan, (list) => {
      data.ttl_cicilan = parseFloat(list.nominal) + parseFloat(data.ttl_cicilan);
    })

    // hitung sisa cicil
    let sisacicil = parseFloat(data.ttl_bayar) - parseFloat(data.ttl_cicilan);

    map(data.dtcicilan, (list, idx) => {
      list.subidx = subidx;
      let i = idx + 1;
      let last = data.dtcicilan.length - 1;
      if (sisacicil === 0 || idx !== last) {
        list.errcicil = "";
      } else {
        if (sisacicil < 0) {
          list.errcicil = "Angsuran Lebih Rp. " + currency.format(isNaN(sisacicil) ? 0 : sisacicil);
        } else {
          list.errcicil = "Sisa Angsuran Rp. " + currency.format(isNaN(sisacicil) ? 0 : sisacicil);
        }
      }
      items.push(
        <React.Fragment key={idx}>
          <div className="form-group col-md-12">
            <label className="font-weight-bold">Angsuran {i}</label>
            <CurrencyFormat
              className="form-control"
              input="number"
              name="nominal"
              // decimalScale="0"
              onChange={this.onChangeNomSubArr}
              value={list.nominal || 0}
              maxLength="25"
              thousandSeparator={true}
              style={{ width: "100%" }}
              fieldname="payment"
              index={subidx}
              subfieldname="dtcicilan"
              subindex={idx}
            />
            {/* notif dibawah field jika dtcicilan tidak sesuai */}
            {list.errcicil !== "" && (
              <small className='form-text text-error'>
                {list.errcicil}
              </small>
            )}
          </div>
          <div className="form-group col-md-12">
            <label className="font-weight-bold">Tanggal Tagihan {i}</label>
            <input
              type="date"
              className="form-control"
              name="tgl_angsuran"
              onChange={this.onChangeDateArr}
              maxLength="200"
              value={list.tgl_angsuran || ""}
              style={{ width: "100%" }}
              fieldname="payment"
              index={subidx}
              subfieldname="dtcicilan"
              subindex={idx}
              min={this.state.dateNow}
              onKeyDown={(e) => e.preventDefault()}
            />
          </div>
        </React.Fragment>
      );
    })
    return items;
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onChangeNom = (e) => {
    // this.setState({ [e.target.name]: remSep(e.target.value) });
    if (e.target.name === "termin") {
      let cicilan = []
      for (let idx = 0; idx < e.target.value; idx++) {
        cicilan.push({
          angsuran: 0,
        })
      }
      this.setState({ cicilan: cicilan });
    }

    this.setState({
      [e.target.name]:
        e.target.value != "" ? parseFloat(remSep(e.target.value)) : 0,
    });
  }

  // onchange array
  onChangeNomArr = (e) => {
    const fieldname = e.target.getAttribute("fieldname")
    const idx = e.target.getAttribute("index")
    const target = e.target.name;
    this.setState((prevState) => {
      let newList = []
      newList = prevState[fieldname];
      var data = newList[idx]
      if (target === 'termin') {
        data.termin = isNaN(parseFloat(remSep(e.target.value))) ? '' : parseFloat(remSep(e.target.value))
      } else if (target === 'dp') {
        data.dp = isNaN(parseFloat(remSep(e.target.value))) ? 0 : parseFloat(remSep(e.target.value))
        if (parseInt(data.dp) !== 0) {
          data.is_dp = 1;
        } else {
          data.is_dp = 0;
        }
      } else if (target === 'potong_persen') {
        data.potong_persen = isNaN(parseFloat(remSep(e.target.value))) ? '' : parseFloat(remSep(e.target.value))
      } else if (target === 'potongan') {
        let value = isNaN(parseFloat(remSep(e.target.value))) ? 0 : parseFloat(remSep(e.target.value));
        if (value >= 100) {
          value = 100;
        }
        data.discount = value;
        data.totalDiscount = (parseFloat(data.nominal) * data.discount) / 100;
        if (data.dp_percent !== null && parseInt(data.dp_percent) !== 0) {
          data.dp = ((parseFloat(data.nominal) - data.totalDiscount) * parseInt(data.dp_percent)) / 100;
        }
      } else {
        data.nominal = isNaN(parseFloat(remSep(e.target.value))) ? '' : parseFloat(remSep(e.target.value))
        if (data.discount !== null && parseInt(data.discount) !== 0) {
          data.totalDiscount = (parseInt(data.nominal) * parseInt(data.discount)) / 100;
        }
        if (data.dp_percent !== null && parseInt(data.dp_percent) !== 0) {
          data.dp = ((parseFloat(data.nominal) - data.totalDiscount) * parseInt(data.dp_percent)) / 100;
          data.is_dp = 1;
        } else {
          data.is_dp = 0;
        }
      }

      const totalSPP = newList.reduce((sum, data) => {
        return parseFloat(sum) + (parseInt(data.is_routine) === 1 ? parseFloat(data.nominal || 0) : 0)
      });

      // buat array cicilan sesuai dengan input termin
      if (e.target.name === "termin") {
        let dt_cicil = [];
        for (let i = 0; i < e.target.value; i++) {
          dt_cicil.push({
            angsuran: 0,
            nominal: 0,
            tgl_angsuran: null,
          })
        }
        newList[idx].dtcicilan = dt_cicil;
      }
      return {
        [fieldname]: newList,
        jumlah_spp: totalSPP,
      }
    });
  }

  onChangeDateArr = (e) => {
    e.preventDefault();
    const idx = e.target.getAttribute("index");
    const idx2 = e.target.getAttribute("subindex");
    const fieldname = e.target.getAttribute("fieldname")
    const subfieldname = e.target.getAttribute("subfieldname")
    let a;
    a = e.target.value;
    if (a === '') {
      a = null
    }
    this.state[fieldname][idx][subfieldname][idx2].tgl_angsuran = a;
    this.forceUpdate();
  }

  // onchange array dlm arrray
  onChangeNomSubArr = (e) => {
    const subfieldname = e.target.getAttribute("subfieldname")
    const subidx = e.target.getAttribute("subindex")
    const fieldname = e.target.getAttribute("fieldname")
    const idx = e.target.getAttribute("index")

    this.setState((prevState) => {
      let newSubList = []
      // input (fieldname) yg (subfieldname) dri index ke (subindex)
      newSubList = prevState[fieldname][parseInt(idx)][subfieldname]
      // ganti value di (fieldname)
      newSubList[parseInt(subidx)][e.target.name] = isNaN(parseFloat(remSep(e.target.value))) ? 0 : parseFloat(remSep(e.target.value))

      let newList = []
      // input semua data (subfieldname)
      newList = prevState[fieldname];
      // input (fieldname) dari newSubList diatas ke (fieldname) milik (subfieldname)
      newList[parseInt(idx)][subfieldname] = newSubList
      // console.log(newSubList)

      return {
        [subfieldname]: newSubList,
      }
    })
  }

  // onchange array dlm arrray
  onChangeDateSubArr = (e) => {
    const subfieldname = e.target.getAttribute("subfieldname")
    const subidx = e.target.getAttribute("subindex")
    const fieldname = e.target.getAttribute("fieldname")
    const idx = e.target.getAttribute("index")

    let newSubList = []
    // input (fieldname) yg (subfieldname) dri index ke (subindex)
    newSubList = this.state[subfieldname][subidx][fieldname];
    // ganti value di (fieldname)
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    newSubList[idx][e.target.name] = a;

    let newList = []
    // input semua data (subfieldname)
    newList = this.state[subfieldname];
    // input (fieldname) dari newSubList diatas ke (fieldname) milik (subfieldname)
    newList[subidx][fieldname] = newSubList
    this.setState({ [subfieldname]: newList });
  }

  onChangePotongan = (e) => {
    let ttl_potongan = 0
    const prmpotong = JSON.parse(localStorage.getItem("DATA_PARAMETER")).prm_potong;
    map(prmpotong, (list, idx) => {
      ttl_potongan = ttl_potongan + list.nominal;
    })

    this.setState(
      { potongan: ttl_potongan, nominal: this.state.upp - ttl_potongan },
      () => {
        let angsur = (this.state.nominal - this.state.dp) / this.state.termin;
        angsur = angsur.toFixed(0);
        // console.log("termin" + this.state.termin);
        // console.log("nominal" + this.state.nominal);
        // console.log("dp" + this.state.dp);
        // console.log(angsur);
        this.setState({ angsuran: angsur });
      }
    );

    map(this.state.prmbayar, (list, idx) => {

    })
  };

  getMaster(id = 0, table) {
    var array = this.state.listPdf[table];
    var arrayfilter = array.filter((data) => { if (data.id == id) return data });
    var name = arrayfilter.length > 0 ? arrayfilter[0].name : '-';
    return name;
  }

  onChangeTotal = (e) => {
    let total =
      parseInt(this.state.us) +
      parseInt(this.state.uk) +
      parseInt(this.state.ukg) +
      parseInt(this.state.pomg);
    this.setState({ totalus: total });
  };

  createSelectItems(item) {
    const pdf_table = item.pdf_table;
    const test = "." + pdf_table;
    let items = [];
    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER"))[pdf_table];

    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id} >
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  onDropdownSelected(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleTolak = () => {
    global
      .swal({
        text: "Alasan tidak sepakat",
        content: "input",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;
        const form_data = new FormData();
        form_data.append("registration_id", this.state.student_id);
        form_data.append("action", "disagree");
        form_data.append("data", confirm);
        // for (var pair of form_data.entries()) {
        //   console.log(pair[0] + ', ' + pair[1]);
        // }
        // return;
        return fetch(global.variable.LINK_API + "flow/update", {
          method: "post",
          headers: {
            Accept: "application/json",
            // "Content-Type": "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: form_data,
        });
      })
      .then(this.handleErrors)
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          global.swal("Success", "Data wawancara berhasil ditolak", "success").then((value) => {
            window.location.href = "/wawancaradetail";
          });
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            let message;
            if ('errors' in err) {
              if (Array.isArray(err.errors)) {
                message = err.errors[0];
              } else {
                message = err.errors;
              }
            } else {
              message = "Koneksi ke server gagal.";
            }
            global.swal("Error", message, "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  handleErrors = async (response) => {
    this.setState({
      isLoading: false,
      isLoading2: false
    })
    if (!response.ok) {
      const errors = await response.clone().json();
      const errorMessage = { code: response.status, ...errors }
      throw errorMessage;
    }
    return response;
  }

  handleSave = (param) => (event) => {
    event.preventDefault();
    let canSave = true
    map(this.state.payment, (list) => {
      if (parseInt(list.is_routine) !== 1) {
        if (parseInt(list.nominal) !== 0) {
          if (list.nominal !== "") {
            //cek termin
            if (list.errtermin || list.errtermin !== "") {
              canSave = false;
              global.swal("Error", list.errtermin, "info");
            }
            // cek cicilan yang 0
            if (list.hasOwnProperty('dtcicilan')) {
              map(list.dtcicilan, (sublist) => {
                if (sublist.errcicil || sublist.errcicil !== "") {
                  canSave = false;
                } else if (sublist.nominal === 0 || sublist.nominal === "") {
                  canSave = false;
                  global.swal("Error", 'Angsuran Tidak Boleh "0"', "info");
                  return false;
                }
                // else if(sublist.tgl_angsuran == ""){
                //   canSave = false;
                //   global.swal("Error", 'Harap isi tanggal angsuran', "info");
                //   return false;
                // }
              })
            }
          } else {
            list.nominal = 0;
            list.dp = 0;
            list.dtcicilan = [];
          }
        } else {
          list.nominal = 0;
          list.dp = 0;
          list.dtcicilan = [];
        }
      } else {
        if (list.nominal === "0" || list.nominal === "") {
          canSave = false;
          global.swal("Error", list.name + ' Tidak Boleh 0', "info");
          return false;
        }
      }
    })
    console.log(this.state.payment);
    if (canSave) {
      if (parseInt(param) === 2) {
        this.setState({ isLoading2: true })
      } else if (parseInt(param) === 1) {
        this.setState({ isLoading: true })
      }
      const form_data = new FormData();
      form_data.append("registration_id", this.state.student_id);
      form_data.append("action", "next");
      form_data.append("interview_status_id", param);
      form_data.append("interview_result", JSON.stringify(this.state.payment))
      // for (var pair of form_data.entries()) {
      //   console.log(pair[0] + ', ' + pair[1]);
      // }
      // return;
      fetch(global.variable.LINK_API + "flow/update", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        body: form_data,
      })
        .then(this.handleErrors)
        .then((response) => response.json())
        .then((resData) => {
          if (resData.message === "Success") {
            const students = JSON.parse(localStorage.getItem("STUDENT_DETAIL"));
            students.interview_result = resData.data.interview_result;
            students.interview_status_id = resData.data.interview_status_id;
            students.interview_name = resData.data.interview_name;
            localStorage.setItem("STUDENT_DETAIL", JSON.stringify(students));
            global.swal("Success", resData.message, "success").then((value) => {
              if (parseInt(param) === 2) {
                this.props.history.push("wawancara")
              } else {
                window.location.href = "/wawancaradetail";
              }
            });
          }
        })
        .catch((err) => {
          if (err) {
            if (err.code !== 403) {
              let message;
              if ('errors' in err) {
                if (Array.isArray(err.errors)) {
                  message = err.errors[0];
                } else {
                  message = err.errors;
                }
              } else {
                message = "Koneksi ke server gagal.";
              }
              global.swal("Error", message, "info");
            }
          } else {
            global.swal.stopLoading();
            global.swal.close();
          }
        });
    }
  };

  handlePrint = (e) => {
    e.preventDefault();
    // localStorage.setItem("flow_id", 8);
    // localStorage.setItem("upp", this.state.upp);
    // localStorage.setItem("spp", this.state.us);
    // localStorage.setItem(
    //   "kegiatan",
    //   this.state.uk + this.state.ukg + this.state.pomg
    // );
    // localStorage.setItem("totalus", this.state.totalus);
    // localStorage.setItem("potongan", this.state.potongan);
    // localStorage.setItem("finalupp", this.state.nominal);
    window.location.href = "/cetakskfinansial";
  };

  download = (param) => (e) => {
    e.preventDefault();
    window.open(
      // "/image/" + param,
      param,
      "_blank",
      "location=yes,height=600,width=600,scrollbars=yes,status=yes"
    );

    //var newTab = window.open(param, "_blank", 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    //newTab.document.body.innerHTML = '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==" width="100px" height="100px">';
  };

  form1() {
    return (
      <div>
        <br></br>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label>Jalur</label>
            <input
              className="form-control"
              type="Text"
              name="fullname"
              maxLength="100"
              value={this.state.registration_type.name}
              disabled
            />
          </div>
          <div className="form-group col-md-12">
            <label>
              Nama Lengkap (Nama siswa dapat di klik untuk melihat detail data
              siswa)
            </label>
            <input
              className="form-control"
              type="Text"
              name="fullname"
              maxLength="100"
              onClick={this.handleClick(this.state.id)}
              value={this.state.fullname}
              style={{ color: "blue" }}
            />
          </div>
          <div className="form-group col-md-6">
            <label>Nama Panggilan</label>
            <input
              className="form-control"
              type="Text"
              name="nickname"
              maxLength="100"
              value={this.state.nickname}
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <label>Alamat</label>
            <input
              className="form-control"
              type="Text"
              name="address"
              maxLength="200"
              value={this.state.address}
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <label>RT</label>
            <input
              className="form-control"
              type="Text"
              name="rt"
              maxLength="5"
              value={this.state.rt}
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <label>RW</label>
            <input
              className="form-control"
              type="Text"
              name="rw"
              maxLength="5"
              value={this.state.rw}
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <label>Kelurahan</label>
            <input
              className="form-control"
              type="Text"
              name="sub_district"
              maxLength="100"
              value={this.state.sub_district}
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <label>Kecamatan</label>
            <input
              className="form-control"
              type="Text"
              name="district"
              maxLength="100"
              value={this.state.district}
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <label>Kabupaten/Kota</label>
            <input
              className="form-control"
              type="Text"
              name="sub_province"
              maxLength="100"
              value={this.state.sub_province}
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <label>Tinggal Dengan</label>
            <input
              className="form-control"
              type="Text"
              name="stay_id"
              maxLength="2"
              value={this.state.stay.name}
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <label>Alamat Sekolah Asal</label>
            <input
              className="form-control"
              type="Text"
              name="school_from_address"
              maxLength="2"
              value={this.state.school_from_address}
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <label>Uang SPP Sekolah Asal</label>
            <CurrencyFormat
              className="form-control"
              value={this.state.spp}
              style={{ width: "100%" }}
              thousandSeparator={true}
              prefix={"Rp. "}
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <label>Daya Listrik</label>
            <input
              className="form-control"
              type="Text"
              name="electrical_power_id"
              maxLength="2"
              value={
                this.state.electrical_power
                  ? this.state.electrical_power.name
                  : ""
              }
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <label>Biaya rata-rata listrik per bulan</label>
            <CurrencyFormat
              className="form-control"
              value={this.state.bill_pln}
              style={{ width: "100%" }}
              thousandSeparator={true}
              prefix={"Rp. "}
              disabled
            />
          </div>
        </div>
      </div>
    );
  }

  form4() {
    let guardian = this.state.fields.guardian;
    let showitem = [];

    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <div key={guardian[i].nik}>
          <hr />
          <div className="row">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Orang Tua{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {this.getMaster(guardian[i].guardian_id, 'pdf_guardian')}{" "}
            </label>
          </div>
          <div className="row">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Nama Lengkap{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].fullname}{" "}
            </label>
          </div>
          <div className="row">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Pekerjaan{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {this.getMaster(guardian[i].occupation_id, 'pdf_occupation')}{" "}
            </label>
          </div>
          <div className="row">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Status Hubungan{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].status_guardian_id !== null ? this.getMaster(guardian[i].status_guardian_id, 'pdf_status_guardian') : guardian[i].status_guardian}{" "}
            </label>
          </div>
          <div className="row">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Penghasilan{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              <CurrencyFormat
                value={guardian[i].salary}
                displayType={"text"}
                style={{ width: "100%" }}
                thousandSeparator={true}
                prefix={"Rp. "}
                disabled
              />{" "}
            </label>
          </div>
          <div className="row">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Alamat{" "}
            </label>
            <label className="col-md-3 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].address}{" "}
            </label>
          </div>
        </div>
      );
    }

    return showitem;
  }

  form5() {
    let guardian = this.state.fields.sibling;
    let showitem = [];

    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <div key={guardian[i].name}>
          <hr />
          <div className="row">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Nama{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].name}{" "}
            </label>
          </div>
          <div className="row">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Kelas{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].class}{" "}
            </label>
          </div>
        </div>
      );
    }

    return showitem;
  }

  form6() {
    let guardian = this.state.fields.achieve;
    let showitem = [];

    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <div key={i}>
          <hr />
          <div className="row">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Jenis{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {this.getMaster(guardian[i].achieve_type_id, 'pdf_achieve_type')}{" "}
            </label>
          </div>
          <div className="row">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Nama Prestasi{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].name}{" "}
            </label>
          </div>
          <div className="row">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Penyelenggara{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].promoter}{" "}
            </label>
          </div>
          <div className="row">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Tingkat{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {this.getMaster(guardian[i].achieve_rank_id, 'pdf_achieve_rank')}{" "}
            </label>
          </div>
          <div className="row">
            <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
              Peringkat{" "}
            </label>
            <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
              {guardian[i].rank}{" "}
            </label>
          </div>
        </div>
      );
    }

    return showitem;
  }

  formdokumen() {
    let guardian = this.state.fields.document_upload;
    let showitem = [];
    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <div className="form-group col-md-12" key={i}>
          <label className="font-weight-bold">{this.getMaster(guardian[i].document_id, 'prm_document_upload')}</label>
          <div>
            {/*<Image alt="Dokumen" style={{width:400}} src={this.state.upload_documents[i].encrypt_64} thumbnail />*/}

            <Link
              to=""
              target="_blank"
              onClick={this.download(guardian[i].link)}
            >
              Lihat Dokumen
            </Link>
          </div>
        </div>
      );
    }
    return showitem;
  }

  formbukti() {
    return (
      /*<Image alt="Bukti transfer" style={{width:400}} src={this.state.upload_transaction.encrypt_64} thumbnail />*/

      <Link
        target="_blank"
        onClick={this.download(this.state.upload_transaction.id)}
      >
        Lihat Bukti Bayar
      </Link>
    );
  }

  handleTest() {
    const data = {
      student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
      flow_id: 4,
      note_verification: this.state.note_verification,
    };

    fetch(global.variable.LINK_API + "flow/update", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          let m = moment();
          m = moment(resData.data.schedule_test_at, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          );
          global
            .swal(
              "Success",
              "Jadwal test adalah " +
              m +
              "\n Pada pukul 13.00 – 15.00 WIB \nDi ruang " +
              resData.data.classroom_id,
              "success"
            )
            .then((value) => {
              window.location.href = "/verifikasibuktibayar";
            });
        }
      });
  }

  handleConfirm = (event) => {
    const data = {
      student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
      flow_id: 100,
      note_verification: this.state.note_verification,
    };

    fetch(global.variable.LINK_API + "flow/update", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {

          global
            .swal("Success", "Berhasil Diterima", "success")
            .then((value) => {
              window.location.href = "/wawancara";
            });
        }
      });
  };

  buildFormNonRoutine(list, idx) {
    let data = null;
    if (parseInt(list.is_routine) === 0) {
      if (parseInt(list.nominal) !== 0) {
        if (parseInt(list.nominal) !== 0) {
          if (list.nominal !== "") {
            data = (
              <>
                {this.createPotonganForm(list, idx)}
                {this.createDPForm(list, idx)}
                <div className="form-group col-md-12">
                  <label className="font-weight-bold">Termin</label>
                  <CurrencyFormat
                    className="form-control"
                    input="number"
                    name="termin"
                    onChange={this.onChangeNomArr}
                    // onBlur={this.onChangePotongan}
                    value={list.termin}
                    maxLength="2"
                    thousandSeparator={true}
                    style={{ width: "100%" }}
                    fieldname="payment"
                    index={idx}
                  />
                </div>
                {(list.termin && list.termin > 0) ? (
                  this.createCicilanForm(list, idx)
                ) : null}
              </>
            )
          }
        }
      }
      return data;
    }
  }

  render() {
    let data_report = JSON.parse(localStorage.getItem("data_report"));
    let counter = 0;

    // if (this.state.termin < 1) {
    //   this.state.termin = 1;
    // }

    // console.log(this.state.payment)
    this.state.totalus = 0;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>Wawancara</MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={this.handleSubmit}>
                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <label>
                              Siswa (Dapat di klik untuk melihat detail data
                              siswa)
                            </label>
                            <input
                              className="form-control"
                              type="Text"
                              name="fullname"
                              maxLength="100"
                              onClick={this.handleClick()}
                              defaultValue={localStorage.getItem("DATA_JALUR") || ''}
                              style={{ color: "blue" }}
                            />
                          </div>
                          {map(this.state.listPdf.prm_ms_student, (item, idx) => {
                            const field = item.field;
                            return (
                              <React.Fragment key={idx}>
                                <div className="form-group col-md-12">
                                  <label>
                                    {item.name}
                                    {/* {item.is_required == 1 ? (<span className="text-danger"> *</span>) : ""} */}
                                  </label>
                                  {item.type == 1 ? (
                                    <>
                                      {item.type_text == "nominal" ? (
                                        <CurrencyFormat
                                          className="form-control"
                                          input="number"
                                          name={item.field}
                                          onChange={this.onChangeNom}
                                          value={this.state.fields[item.field] || ''}
                                          maxLength="25"
                                          thousandSeparator={true}
                                          disabled
                                          style={{ width: "100%" }}
                                        />
                                      ) : (
                                        <input
                                          className="form-control"
                                          type={item.type_text}
                                          name={item.field}
                                          maxLength="f100"
                                          value={this.state.fields[item.field] || ''}
                                          onChange={this.onChange}
                                          disabled
                                          required={
                                            item.is_required == 1
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <select
                                        id={idx}
                                        className="form-control"
                                        name={item.field}
                                        disabled
                                        onChange={this.onDropdownSelected.bind(
                                          this
                                        )}
                                        value={this.state.fields[item.field] || ''}
                                      >
                                        <option value="">
                                          - Pilih {item.name} -
                                        </option>
                                        {this.createSelectItems(item)}
                                      </select>
                                    </>
                                  )}
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                        {this.state.is_show ? (
                          <div>
                            <hr />
                            <label
                              className="font-weight-bold"
                              style={{
                                fontSize: 20,
                                textDecorationLine: "underline",
                              }}
                            >
                              Data Orang Tua
                            </label>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.is_show && this.state.fields.guardian.length > 0 ? this.form4() : ""}
                        {this.state.is_show ? (
                          <div>
                            <hr />
                            <label
                              className="font-weight-bold"
                              style={{
                                fontSize: 20,
                                textDecorationLine: "underline",
                              }}
                            >
                              Saudara yang masih bersekolah di{" "}
                              {this.state.nama_sekolah}
                            </label>
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.is_show && this.state.fields.sibling.length > 0 ? this.form5() : ""}

                        {this.state.is_show ? (
                          <div>
                            <hr />
                            <label
                              className="font-weight-bold"
                              style={{
                                fontSize: 20,
                                textDecorationLine: "underline",
                              }}
                            >
                              Prestasi
                            </label>
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.is_show && this.state.fields.achieve.length > 0 ? this.form6() : ""}

                        <div>
                          <hr />
                          <label
                            className="font-weight-bold"
                            style={{
                              fontSize: 20,
                              textDecorationLine: "underline",
                            }}
                          >
                            Catatan Tentang Pendaftar
                          </label>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <label className="font-weight-bold">Catatan</label>
                            <textarea
                              className="form-control"
                              as="textarea"
                              disabled
                              rows="5"
                              name="note_verification"
                              value={this.state.note_verification}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                        <hr></hr>
                        {
                          this.state.is_show ? (
                            map(this.state.payment, (list, idx) => {
                              let i = idx + 1

                              // ambil data dari db jika list.nominal belum ada
                              // list.nominal = list.nominal ? list.nominal : list.value;
                              list.nominal2 = list.nominal ? list.nominal : list.value

                              //hitung jumlah potongan
                              // if (list.is_potong === 1 && list.potong_persen) {
                              //   list.potongan = parseFloat(list.nominal2) * parseFloat(list.potong_persen) / 100;
                              // } else if (list.is_potong === 2 && !isEmpty(list.prm_potong)) {
                              //   list.potongan = 0;
                              //   map(list.prm_potong, (list_pot) => {
                              //     list.potongan = parseFloat(list.potongan) + parseFloat(list_pot.nominal);
                              //   })
                              // } else {
                              // list.potongan = 0;
                              // }

                              //hitung total bayar
                              list.ttl = parseFloat(list.nominal2) - parseFloat(list.totalDiscount);
                              if (list.dp_percent !== null && parseInt(list.dp_percent) !== 0) {
                                list.dp = list.dp ? list.dp : 0;
                                list.ttl_bayar = parseFloat(list.ttl) - parseFloat(list.dp);
                              } else {
                                if (parseInt(list.dp) !== 0) {
                                  list.ttl_bayar = parseFloat(list.ttl) - parseFloat(list.dp);
                                } else {
                                  list.ttl_bayar = parseFloat(list.ttl)
                                }
                              }

                              // hitung spp perbulan
                              if (parseInt(list.is_routine) === 1) {
                                this.state.totalus = parseFloat(this.state.totalus) + parseFloat(list.nominal2);
                              } else {
                                if (list.nominal !== 0) {
                                  if (list.nominal !== "") {
                                    list.termin = list.termin ? list.termin : 0;
                                    if (list.termin < 1) {
                                      list.errtermin = "Termin tidak boleh kurang dari 1"
                                    } else {
                                      list.errtermin = "";
                                    }
                                  } else {
                                    list.errtermin = "";
                                  }
                                } else {
                                  list.errtermin = "";
                                }
                              }
                              return (
                                <React.Fragment key={idx}>
                                  <div className="rowHorizontal">
                                    <div className="form-group col-md-12">
                                      <label><b>{list.name}</b></label>
                                      <CurrencyFormat
                                        className="form-control"
                                        input="number"
                                        id={idx}
                                        name={`payment_${list.id}`}
                                        onChange={this.onChangeNomArr}
                                        value={parseInt(list.is_edit) === 1 ? list.nominal || 0 : list.value}
                                        maxLength="25"
                                        thousandSeparator={true}
                                        style={{ width: "100%" }}
                                        disabled={parseInt(list.is_edit) === 1 ? false : true}
                                        required
                                        fieldname="payment"
                                        index={idx}

                                      />
                                    </div>
                                  </div>
                                  {this.buildFormNonRoutine(list, idx)}
                                  {idx === this.state.payment.length - 1 ? this.createSPPForm() : null}
                                </React.Fragment>
                              )
                            })
                          ) : null}
                        <br></br>

                        <div className="col-12">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <MDBBtn
                                disabled={
                                  parseInt(this.state.interview_status_id) === 1
                                }
                                onClick={this.handleTolak}
                                block
                                style={{
                                  // margin: 5,
                                  height: 50,
                                  // backgroundColor: "red",
                                }}
                                color="danger"
                              >
                                Tidak Sepakat
                              </MDBBtn>
                            </div>
                            <div className="col-md-4 col-12">
                              <MDBBtn
                                disabled={
                                  parseInt(this.state.interview_status_id) === 1 ? parseInt(this.state.interview_status_id) === 1 : this.state.isLoading2
                                }
                                onClick={this.handleSave("2")}
                                type="submit"
                                block
                                style={{
                                  // margin: 5,
                                  height: 50,
                                  backgroundColor: "#e6e600",
                                }}
                                color="warning"
                              >
                                {
                                  this.state.isLoading2 ? (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  ) : 'Banding'
                                }
                              </MDBBtn>
                            </div>
                            <div className="col-md-4 col-12">
                              <MDBBtn
                                onClick={this.handleSave("1")}
                                disabled={this.state.isLoading}
                                block
                                style={{
                                  // margin: 5,
                                  height: 50,
                                  backgroundColor: "#33cc33",
                                }}
                                color="success"
                              >
                                {
                                  this.state.isLoading ? (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  ) : 'Sepakat'
                                }
                              </MDBBtn>
                            </div>
                          </div>

                          {this.state.payment &&
                            parseInt(this.state.interview_status_id) === 1 &&
                            this.state.payment.length ? (
                            <div className="col-12 p-0 mt-2">
                              <MDBBtn onClick={this.handlePrint} block style={{ height: 50 }} color="info" >
                                Cetak Surat Pernyataan
                              </MDBBtn>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Wawancara_detail;
